.ant-pro-statistic-card-chart {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
}
.ant-pro-statistic-card-chart-left {
  margin-top: 0;
  margin-right: 16px;
}
.ant-pro-statistic-card-chart-right {
  margin-top: 0;
  margin-left: 16px;
}
.ant-pro-statistic-card-content {
  display: flex;
  flex-direction: column;
}
.ant-pro-statistic-card-content-horizontal {
  flex-direction: row;
}
.ant-pro-statistic-card-content-horizontal .ant-pro-statistic-card-chart {
  align-items: center;
  align-self: flex-start;
}
.ant-pro-statistic-card-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: rgba(0, 0, 0, 0.08) 1px solid;
}

.ant-pro-card-statistic {
  display: flex;
  font-size: 14px;
}
.ant-pro-card-statistic + .ant-pro-card-statistic {
  margin-top: 4px;
}
.ant-pro-card-statistic-tip {
  margin-left: 4px;
}
.ant-pro-card-statistic-wrapper {
  display: flex;
  width: 100%;
}
.ant-pro-card-statistic-icon {
  margin-right: 16px;
}
.ant-pro-card-statistic-trend-icon {
  width: 0;
  height: 0;
  border-right: 3.5px solid transparent;
  border-bottom: 9px solid #000;
  border-left: 3.5px solid transparent;
}
.ant-pro-card-statistic-trend-icon-up {
  transform: rotate(0deg);
}
.ant-pro-card-statistic-trend-icon-down {
  transform: rotate(180deg);
}
.ant-pro-card-statistic-content {
  width: 100%;
}
.ant-pro-card-statistic-description {
  width: 100%;
}
.ant-pro-card-statistic .ant-statistic-title {
  color: rgba(0, 0, 0, 0.65);
}
.ant-pro-card-statistic-layout-inline {
  display: inline-flex;
  color: rgba(0, 0, 0, 0.45);
}
.ant-pro-card-statistic-layout-inline .ant-statistic-title {
  margin-right: 6px;
  margin-bottom: 0;
}
.ant-pro-card-statistic-layout-inline .ant-statistic-content {
  color: rgba(0, 0, 0, 0.45);
}
.ant-pro-card-statistic-layout-inline .ant-statistic-title,
.ant-pro-card-statistic-layout-inline .ant-statistic-content,
.ant-pro-card-statistic-layout-inline .ant-statistic-content-suffix,
.ant-pro-card-statistic-layout-inline .ant-statistic-content-prefix,
.ant-pro-card-statistic-layout-inline .ant-statistic-content-value-decimal {
  font-size: 12px;
}
.ant-pro-card-statistic-layout-horizontal {
  display: flex;
  justify-content: space-between;
}
.ant-pro-card-statistic-layout-horizontal .ant-statistic-title {
  margin-bottom: 0;
}
.ant-pro-card-statistic-layout-horizontal .ant-statistic-content-value {
  font-weight: 500;
}
.ant-pro-card-statistic-layout-horizontal .ant-statistic-title,
.ant-pro-card-statistic-layout-horizontal .ant-statistic-content,
.ant-pro-card-statistic-layout-horizontal .ant-statistic-content-suffix,
.ant-pro-card-statistic-layout-horizontal .ant-statistic-content-prefix,
.ant-pro-card-statistic-layout-horizontal .ant-statistic-content-value-decimal {
  font-size: 14px;
}
.ant-pro-card-statistic-trend-up .ant-statistic-content {
  color: #f5222d;
}
.ant-pro-card-statistic-trend-up .ant-statistic-content .ant-pro-card-statistic-trend-icon {
  border-bottom-color: #f5222d;
}
.ant-pro-card-statistic-trend-down .ant-statistic-content {
  color: #389e0d;
}
.ant-pro-card-statistic-trend-down .ant-statistic-content .ant-pro-card-statistic-trend-icon {
  border-bottom-color: #52c41a;
}

.ant-pro-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
}
.ant-pro-card-border {
  border: 1px solid #f0f0f0;
}
.ant-pro-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-pro-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-pro-card-size-small .ant-pro-card-header {
  padding: 8px 12px;
  padding-bottom: 0;
}
.ant-pro-card-size-small .ant-pro-card-header-border {
  padding-bottom: 8px;
}
.ant-pro-card-size-small .ant-pro-card-title {
  font-size: 14px;
}
.ant-pro-card-size-small .ant-pro-card-body {
  padding: 12px;
}
.ant-pro-card-ghost {
  background-color: transparent;
}
.ant-pro-card-ghost > .ant-pro-card-header {
  padding-right: 0;
  padding-bottom: 16px;
  padding-left: 0;
}
.ant-pro-card-ghost > .ant-pro-card-body {
  padding: 0;
  background-color: transparent;
}
.ant-pro-card-split > .ant-pro-card-body {
  padding: 0;
}
.ant-pro-card-split-vertical {
  border-right: 1px solid #f0f0f0;
}
.ant-pro-card-split-horizontal {
  border-bottom: 1px solid #f0f0f0;
}
.ant-pro-card-contain-card > .ant-pro-card-body {
  display: flex;
  flex-direction: row;
}
.ant-pro-card-contain-card > .ant-pro-card-body-column {
  flex-direction: column;
}
.ant-pro-card-collapse > .ant-pro-card-header {
  padding-bottom: 16px;
  border-bottom: 0;
}
.ant-pro-card-collapse > .ant-pro-card-body {
  display: none;
}
.ant-pro-card-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  padding-bottom: 0;
}
.ant-pro-card-header-border {
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-pro-card-title {
  font-size: 16px;
}
.ant-pro-card-title-collapsible {
  cursor: pointer;
}
.ant-pro-card-type-inner .ant-pro-card-header {
  background-color: #fafafa;
}
.ant-pro-card-collapsible-icon {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.75);
}
.ant-pro-card-collapsible-icon svg {
  transition: transform 0.2s;
}
.ant-pro-card-title {
  font-weight: 500;
}
.ant-pro-card-body {
  display: block;
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
}
.ant-pro-card-body-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pro-card-span-0 {
  display: none;
}
.ant-pro-card-span-24 {
  flex-grow: 0;
  width: 100%;
}
.ant-pro-card-span-23 {
  flex-grow: 0;
  width: 95.83333333%;
}
.ant-pro-card-span-22 {
  flex-grow: 0;
  width: 91.66666667%;
}
.ant-pro-card-span-21 {
  flex-grow: 0;
  width: 87.5%;
}
.ant-pro-card-span-20 {
  flex-grow: 0;
  width: 83.33333333%;
}
.ant-pro-card-span-19 {
  flex-grow: 0;
  width: 79.16666667%;
}
.ant-pro-card-span-18 {
  flex-grow: 0;
  width: 75%;
}
.ant-pro-card-span-17 {
  flex-grow: 0;
  width: 70.83333333%;
}
.ant-pro-card-span-16 {
  flex-grow: 0;
  width: 66.66666667%;
}
.ant-pro-card-span-15 {
  flex-grow: 0;
  width: 62.5%;
}
.ant-pro-card-span-14 {
  flex-grow: 0;
  width: 58.33333333%;
}
.ant-pro-card-span-13 {
  flex-grow: 0;
  width: 54.16666667%;
}
.ant-pro-card-span-12 {
  flex-grow: 0;
  width: 50%;
}
.ant-pro-card-span-11 {
  flex-grow: 0;
  width: 45.83333333%;
}
.ant-pro-card-span-10 {
  flex-grow: 0;
  width: 41.66666667%;
}
.ant-pro-card-span-9 {
  flex-grow: 0;
  width: 37.5%;
}
.ant-pro-card-span-8 {
  flex-grow: 0;
  width: 33.33333333%;
}
.ant-pro-card-span-7 {
  flex-grow: 0;
  width: 29.16666667%;
}
.ant-pro-card-span-6 {
  flex-grow: 0;
  width: 25%;
}
.ant-pro-card-span-5 {
  flex-grow: 0;
  width: 20.83333333%;
}
.ant-pro-card-span-4 {
  flex-grow: 0;
  width: 16.66666667%;
}
.ant-pro-card-span-3 {
  flex-grow: 0;
  width: 12.5%;
}
.ant-pro-card-span-2 {
  flex-grow: 0;
  width: 8.33333333%;
}
.ant-pro-card-span-1 {
  flex-grow: 0;
  width: 4.16666667%;
}

.ant-pro-card-loading {
  overflow: hidden;
}
.ant-pro-card-loading .ant-pro-card-body {
  user-select: none;
}
.ant-pro-card-loading-content p {
  margin: 0;
}
.ant-pro-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 2px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.ant-pro-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-pro-card-actions::before {
  display: table;
  content: '';
}
.ant-pro-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-pro-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-pro-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-pro-card-actions > li > span:hover {
  color: #1890ff;
  transition: color 0.3s;
}
.ant-pro-card-actions > li > span a:not(.ant-btn),
.ant-pro-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-pro-card-actions > li > span a:not(.ant-btn):hover,
.ant-pro-card-actions > li > span > .anticon:hover {
  color: #1890ff;
}
.ant-pro-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-pro-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-pro-core-label-tip {
  display: flex;
  align-items: center;
}
.ant-pro-core-label-tip-icon {
  display: block;
  margin-left: 4px;
  cursor: pointer;
}
.ant-pro-core-label-tip-subtitle {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}

.ant-pro-card-divider {
  width: 1px;
  margin: 24px 8px;
  background-color: #f0f0f0;
}
.ant-pro-card-divider-horizontal {
  width: initial;
  height: 1px;
  margin: 8px 24px;
}
.ant-pro-card-size-small .ant-pro-card-divider {
  margin: 12px 8px;
}
.ant-pro-card-size-small .ant-pro-card-divider-horizontal {
  margin: 8px 12px;
}

.ant-pro-card-operation {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 24px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
}

.ant-pro-card-tabs .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
.ant-pro-card-tabs .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-list {
  margin-top: 8px;
  padding-left: 16px;
}
.ant-pro-card-tabs .ant-tabs-bottom > .ant-tabs-nav {
  margin-top: 0;
}
.ant-pro-card-tabs .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-list {
  padding-left: 16px;
}
.ant-pro-card-tabs .ant-tabs-left .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  padding-left: 0;
}
.ant-pro-card-tabs .ant-tabs-left > .ant-tabs-nav {
  margin-right: 0;
}
.ant-pro-card-tabs .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list {
  padding-top: 16px;
}
.ant-pro-card-tabs .ant-tabs-right .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  padding-right: 0;
}
.ant-pro-card-tabs .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list {
  padding-top: 16px;
}

